import React from 'react';
import './baseView.scss';

interface Props {
  header?: React.ReactNode;
  main?: React.ReactNode;
  footer?: React.ReactNode;
  semiModal?: React.ReactNode;
}

const BaseView:React.FC<Props> = ({
  header,
  main,
  footer,
  semiModal
}) => {
  return (
    <div className='baseView'>
      <header>
        {header}
      </header>
      <main>
        {main}
      </main>
      <footer>
        {footer}
      </footer>
      {semiModal}
    </div>
  );
}

export default BaseView;
