import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import HomeView from './home/home.tsx';

function App() {
  return (
    <BrowserRouter>      
      <Routes>
        <Route exact path="/" element={<HomeView />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
