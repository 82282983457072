import { Link  } from 'react-router-dom';
import React from 'react';
import BaseView from '../component/view/baseView.tsx';
import './home.scss';
import poster1 from './poster1.png';
import picture1 from './picture1.jpg';
import picture2 from './picture2.jpg';
import picture3 from './picture3.jpg';
import kikaku1 from './kikaku1.png';
import kikaku2 from './kikaku2.png';
import kikaku3 from './kikaku3.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { PiForkKnifeBold } from "react-icons/pi";
import { BiSolidDrink } from "react-icons/bi";

const Slider = () => {
  return (
    <Swiper
      modules={[Autoplay, Navigation]}
      pagination={{ clickable: false }}
      mousewheel={true}
      autoplay={{delay: 5000, disableOnInteraction: false}}
      speed={500}
      centeredSlides={true}
      loop={true}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 50
        },
        500: {
          slidesPerView: 2.2,
          spaceBetween: 50
        }
      }}
    >
      <SwiperSlide>
        <img className='slider-back-img' src={poster1} alt=''/>
        <img className='slider-img' src={poster1} alt=''/>
      </SwiperSlide>
      <SwiperSlide>
        <img className='slider-back-img' src={picture1} alt=''/>
        <img className='slider-img' src={picture1} alt=''/>
      </SwiperSlide>
      <SwiperSlide>
        <img className='slider-back-img' src={picture2} alt=''/>
        <img className='slider-img' src={picture2} alt=''/>
      </SwiperSlide>
      <SwiperSlide>
        <img className='slider-back-img' src={picture3} alt=''/>
        <img className='slider-img' src={picture3} alt=''/>
      </SwiperSlide>
    </Swiper>
  );
}

const EntryNumber = () => {
  const [data, setData] = useState(undefined);

  useEffect (
    () => {
      const time = setInterval(
        () => {
          fetch('https://script.googleusercontent.com/macros/echo?user_content_key=FYGYH2QbNFn2ygaFNzCVUMFarq4KNFE3Ppyk7wulICQoS41FOwuoqLRmooYAwcIfGE3FQ4-uvl3K5lWaC0BUM0EQOOr5g4GOm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnJBczjIppnvsKWToIel4kK1k6ir2lYEWUOquxhhw22r2ZgtPL8ZpImo4KnZ38cHigvIwilTvyy1zV5k_smo0LqmCtAAhgek6qNz9Jw9Md8uu&lib=M6OqXJJcaIeDfnlY1c8_Bpg0Nf6fD823V', {
            mode: 'cors'
          })
          .then(response => response.json())
          .then(data => {
            setData(data)
          })
          .catch(error => {
            console.error('リクエストエラー:', error);
          });
        }, 5000);
      return () => clearInterval(time);
    }, []
  );
  return (
    <div className='mx-lg'>
      {data === undefined ? '' : <div className='fs-1'>{data[0]}</div>}
    </div>
  );
}

const EntryForm = () => {
  return (
    <Link to={'https://forms.gle/hcha8X9Y5ZT4PTgV9'} target='_blank'>
      <div className='entry-btn text-center'><label>ENTRY</label></div>
    </Link>  
  );
}

const FAQ = () => {
  return (
    <div className='qa-content mw-600'>
      <div className='question'>参加の締め切りはありますか？</div>
      <div className='answer'>2024年4月20日(土)で一度締め切らせていただこうと思っています。1週間前で会場に人数を提出いたします。その後も追加参加も可能です！</div>
      <div className='question'>先生方は参加されますでしょうか？</div>
      <div className='answer'>現状は未定です。</div>
      <div className='question'>食事形式を教えてください！</div>
      <div className='answer'>当日は立食形式を予定しています。</div>
      <div className='question'>企画はありますか？</div>
      <div className='answer'>少しだけ実施します！</div>
      <div className='question'>服装の指定はありますか？</div>
      <div className='answer'>特にありません。私服でお越しください。</div>
    </div>
  );
}

const Event = () => {
  return (
    <div className='event-area mw-600'>
      <img className='kikaku-img' src={kikaku1} alt='企画画像' />
      <div className='event-swiper'>
        <div>スライドイメージ</div>
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true, el: '#pagination' }}
          mousewheel={true}
          navigation
          centeredSlides={true}
        >
          <SwiperSlide>
            <img className='kikaku-img' src={kikaku2} alt='サンプル1'/>
          </SwiperSlide>
          <SwiperSlide>
            <img className='kikaku-img' src={kikaku3} alt='サンプル2'/>
          </SwiperSlide>
        </Swiper>
        <div id='pagination' className='swiper-pagination custom-pagination'></div>
      </div>
      <div>当日来られない方も動画などで作成いただくことも可能です！</div>
      <h3>提出方法</h3>
      <div>livefirst.event@gmail.com にお送りください。</div>
      <div>タイトルに 「【NMK4】スライド提出（名前）」をつけて添付をお願いします。</div>
    </div>
  );
}

const Footer = () => {
  return (
    <div className='text-center'>主催・制作：並木中等4期同窓会実行委員会</div>
  )
}

function Home() {
  return (
    <div className="home">
      <Slider />
      <div className='mx-5 my-3 p-3 text-center entry-count'>
        <div className='fs-5'>現在の参加予定数</div>
        <EntryNumber /> 
      </div>
      <div className='text-center entry-form'>
        <EntryForm />
        <div className='outline'>
          <div>日程：2024年4月27日（土）</div>
          <div>場所：カーサアスティオン</div>
          <div>時間：16:20〜18:20（受付開始16:10〜）</div>
          <div>参加費：7000円</div>
          <div>※参加費には飲食費、会場費、制作費を含みます</div>
      </div>
      <div className='text-center p-3'>
        <div className='title fs-3'>Time Table</div>
          <div className='time-table text-center align-items-center'>
            <label className='fs-5'>後日公開</label>
          </div>
        </div>
      </div>
      <div className='text-center p-3'>
        <div className='title fs-3'>Menu</div>
        <div className='menu-content'>
          <div className='d-flex'>
            <div className='icon'>
              <PiForkKnifeBold />
            </div>
            <div className='content col text-start'>
              カラフルチーズスティック/彩野菜のサラダ/サーモンとポテトのモミザ風/鴨の燻製と地中海風クスクス/特製ピラフ/新鮮卵のフリッタータ/1.5斤の大きなピザトースト/シェフのおすすめパスタ/スパイシーポテト/グリチキン&ポーク and more...
            </div>
          </div>
          <div className='d-flex mt-2'>
            <div className='icon'>
              <BiSolidDrink />
            </div>
            <div className='content col text-start'>
              グラスビール/焼酎/ウイスキー/カシス/ジン and more...
            </div>
          </div>
        </div>
      </div>
      <div className='text-center p-3 map-width'>
        <div className='title fs-3'>Access</div>
        <div>〒150-0043 東京都渋谷区道玄坂2-11-6 TOP HILLS GARDEN 道玄坂 3F</div>
        <div>渋谷駅 / 徒歩5〜10分</div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.7762738051656!2d139.6933288757867!3d35.65788337259478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188caa71a8df23%3A0x9d9d06bf7cf3bb9b!2zQ2FzYSBBc3RlaW9u77yI44Kr44O844K1IOOCouOCueODhuOCo-OCquODs--8iQ!5e0!3m2!1sja!2sjp!4v1704985698833!5m2!1sja!2sjp" width="600" height="450" loading="lazy"></iframe>
      </div>
      <div className='text-center p-3'>
        <div className='title fs-3'>FAQ</div>
        <FAQ />
      </div>
      <div className='text-center p-3'>
        <div className='title fs-3'>企画</div>
        <Event />
      </div>
    </div>
  );
}

function HomeView() {
  return (
    <BaseView main={<Home />} footer={<Footer />} />
  );
}
export default HomeView;
